<template>
  <div ref="wrapper">
    <slot name="reference"></slot>

    <el-dialog
      title="Корпоративные цели компании"
      :visible.sync="show"
    >
      <el-row v-html="settings.corporate_target_1"></el-row>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="show = false">Закрыть</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";
import {mapGetters} from "vuex";

export default {
  name: "corporate-targets-modal",
  components: {},
  props: {},

  data() {
    return {
      show: false,
    }
  },
  computed: {
    ...mapGetters('settings', {
      settings: 'settings',
    }),
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  watch: {},
  methods: {
    showModal() {
      this.show = true;
    }
  },

  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showHistory);
    }
  }

}
</script>

<style>

</style>